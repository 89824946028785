import React from 'react'
import Layout from '../components/Layout'
import { AnexSignUp } from '@anex/signup-component'

const windowGlobal = typeof window !== 'undefined' && window

const SignUpPage = () => (
  <Layout>
    <main id="page-sign-up" className="single">
      <div className="container">
        <AnexSignUp
          wholesaleCustomerCode="TOM"
          usingBootstrap3={true}
          anexApiBaseUrl={windowGlobal['ANEX_API_ENDPOINT']}
          anexApiToken={windowGlobal['ANEX_API_TOKEN']}
          bPointBaseUrl={windowGlobal['BPOINT_API_ENDPOINT']}
          eWayBaseUrl={windowGlobal['EWAY_API_ENDPOINT']}
          googleApiKey={windowGlobal['GOOGLE_API_KEY']}
          saleFailureAdvice={windowGlobal['SALE_FAILURE_ADVICE']}
        />
      </div>
    </main>
  </Layout>
)
export default SignUpPage
